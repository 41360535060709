var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("modal-manager"),
      _c("router-view", { attrs: { name: "navbar" } }),
      _c("div", { class: { "app-container": !_vm.routeIsLogin } }, [
        _c(
          "div",
          { staticClass: "sidebar-container" },
          [_c("router-view", { attrs: { name: "sidebar" } })],
          1
        ),
        _c("div", { staticClass: "page-container" }, [_c("router-view")], 1),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }