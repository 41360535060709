var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal__container" }, [
    _c(
      "div",
      { staticClass: "modalDialog" },
      [
        _c("icon", {
          staticClass: "modalcloseButton",
          attrs: { name: "cancel" },
          nativeOn: {
            click: function ($event) {
              return _vm.SET_ACTIVE_MODAL({ modal: null })
            },
          },
        }),
        _c("div", { staticClass: "modalContent" }, [
          _c("div", { staticClass: "modalContent__body" }, [
            _vm.hasEliteSubscription
              ? _c(
                  "div",
                  { staticClass: "formGroup h-textCenter" },
                  [
                    _c("h4", { staticClass: "modal__title h-mb-10" }, [
                      _vm._v(
                        "You are subscribed to " +
                          _vm._s(_vm.authenticatedUser.subscription_type)
                      ),
                    ]),
                    _c("p", { staticClass: "h-mb-20" }, [
                      _vm._v(
                        "Click below to downgrade your license and go back to a Standard membership"
                      ),
                    ]),
                    _c("div"),
                    _vm.isLoading
                      ? _c("VLoadSpinner")
                      : _c(
                          "button",
                          {
                            staticClass: "btn btn--danger",
                            on: { click: _vm.cancelSubscription },
                          },
                          [_vm._v("Cancel subscription")]
                        ),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "formGroup" },
                  [
                    _c("h4", { staticClass: "modal__title" }, [
                      _vm._v("Upgrade to an Elite account"),
                    ]),
                    _c("div", {
                      staticClass: "h-mb-20",
                      attrs: { id: "dropin-container" },
                    }),
                    _vm.isLoading
                      ? _c("VLoadSpinner")
                      : _c(
                          "button",
                          {
                            staticClass: "btn btn--primary",
                            on: { click: _vm.subscribe },
                          },
                          [_vm._v("Start Your 30 Day Free Trial")]
                        ),
                  ],
                  1
                ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.paymentErrorShort,
                    expression: "paymentErrorShort",
                  },
                ],
                staticClass: "formGroup form__error",
              },
              [_vm._v(_vm._s(_vm.paymentErrorShort))]
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }