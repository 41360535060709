var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      staticClass: "sidebar",
      class: { "navbar--wTopline": _vm.toplineBannerShow },
    },
    [
      _c("li", { staticClass: "sidebar__item sidebar__logo" }, [
        _c(
          "a",
          { attrs: { href: "https://thecharts.com" } },
          [
            _c("icon", {
              attrs: { name: "logo", width: "150", height: "60", original: "" },
            }),
          ],
          1
        ),
      ]),
      _c(
        "router-link",
        { staticClass: "sidebar__item", attrs: { to: { name: "Dashboard" } } },
        [
          _c(
            "li",
            [
              _c("icon", {
                staticClass: "sidebar__icon",
                attrs: { name: "home" },
              }),
              _vm._v("Dashboard"),
            ],
            1
          ),
        ]
      ),
      _c(
        "router-link",
        {
          staticClass: "sidebar__item",
          attrs: { to: { name: "BeatsCatalog", params: { pageIndex: 1 } } },
        },
        [
          _c(
            "li",
            [
              _c("icon", {
                staticClass: "sidebar__icon",
                attrs: { name: "beats" },
              }),
              _vm._v("Beats"),
            ],
            1
          ),
        ]
      ),
      _c(
        "ul",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.menuIsActive,
              expression: "menuIsActive",
            },
          ],
          staticClass: "sidebar__submenu",
        },
        [
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  staticClass: "sidebar__item",
                  attrs: {
                    to: {
                      name: "BeatsUpload",
                      query: { type: _vm.UploadType.NewBeat },
                    },
                  },
                },
                [_vm._v("Upload Files")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  staticClass: "sidebar__item",
                  attrs: {
                    to: {
                      name: "BeatsUpload",
                      query: { type: _vm.UploadType.TaggedBeat },
                    },
                  },
                },
                [_vm._v("Upload Tagged Beats")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  staticClass: "sidebar__item",
                  attrs: {
                    to: {
                      name: "BeatsUpload",
                      query: { type: _vm.UploadType.Public },
                    },
                  },
                },
                [_vm._v("Upload Artworks")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  staticClass: "sidebar__item",
                  attrs: { to: { name: "BeatsReorder" } },
                },
                [_vm._v("Reorder Beats")]
              ),
            ],
            1
          ),
          _c(
            "li",
            [
              _c(
                "router-link",
                {
                  staticClass: "sidebar__item",
                  attrs: { to: { name: "BeatsPrivateVault" } },
                },
                [_vm._v("Private Beats Vault")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "router-link",
        { staticClass: "sidebar__item", attrs: { to: { name: "Licenses" } } },
        [
          _c(
            "li",
            [
              _c("icon", {
                staticClass: "sidebar__icon",
                attrs: { name: "license-options" },
              }),
              _vm._v("License Options"),
            ],
            1
          ),
        ]
      ),
      _c(
        "router-link",
        { staticClass: "sidebar__item", attrs: { to: { name: "Contracts" } } },
        [
          _c(
            "li",
            [
              _c("icon", {
                staticClass: "sidebar__icon",
                attrs: { name: "contract" },
              }),
              _vm._v("Contracts"),
            ],
            1
          ),
        ]
      ),
      _c(
        "router-link",
        { staticClass: "sidebar__item", attrs: { to: { name: "Services" } } },
        [
          _c(
            "li",
            [
              _c("icon", {
                staticClass: "sidebar__icon",
                attrs: { name: "network" },
              }),
              _vm._v("Services"),
            ],
            1
          ),
        ]
      ),
      _c(
        "router-link",
        { staticClass: "sidebar__item", attrs: { to: { name: "SoundKits" } } },
        [
          _c(
            "li",
            [
              _c("icon", {
                staticClass: "sidebar__icon",
                attrs: { name: "drums" },
              }),
              _vm._v("Sound Kits"),
            ],
            1
          ),
        ]
      ),
      _c(
        "router-link",
        { staticClass: "sidebar__item", attrs: { to: { name: "Coupons" } } },
        [
          _c(
            "li",
            [
              _c("icon", {
                staticClass: "sidebar__icon",
                attrs: { name: "coupon" },
              }),
              _vm._v("Coupons"),
            ],
            1
          ),
        ]
      ),
      _c(
        "router-link",
        {
          staticClass: "sidebar__item",
          attrs: { to: { name: "Orders", params: { pageIndex: 1 } } },
        },
        [
          _c(
            "li",
            [
              _c("icon", {
                staticClass: "sidebar__icon",
                attrs: { name: "wallet" },
              }),
              _vm._v("Sales"),
            ],
            1
          ),
        ]
      ),
      _c(
        "router-link",
        {
          staticClass: "sidebar__item",
          attrs: { to: { name: "CoProducers" } },
        },
        [
          _c(
            "li",
            [
              _c("icon", {
                staticClass: "sidebar__icon",
                attrs: { name: "co-producer" },
              }),
              _vm._v("Co-producers"),
            ],
            1
          ),
        ]
      ),
      _c(
        "router-link",
        {
          staticClass: "sidebar__item",
          attrs: { to: { name: "AmplifyPlayer" } },
        },
        [
          _c(
            "li",
            [
              _c("icon", {
                staticClass: "sidebar__icon",
                attrs: { name: "code" },
              }),
              _vm._v("Amplify Player"),
            ],
            1
          ),
        ]
      ),
      _c(
        "router-link",
        { staticClass: "sidebar__item", attrs: { to: { name: "Product" } } },
        [
          _c(
            "li",
            [
              _c("icon", {
                staticClass: "sidebar__icon",
                attrs: { name: "product" },
              }),
              _vm._v("Product Offer"),
            ],
            1
          ),
        ]
      ),
      _c(
        "router-link",
        { staticClass: "sidebar__item", attrs: { to: { name: "Elitepage" } } },
        [
          _c(
            "li",
            [
              _c("icon", {
                staticClass: "sidebar__icon",
                attrs: { name: "elitepage" },
              }),
              _vm._v("Elite Profile"),
            ],
            1
          ),
        ]
      ),
      _c("li", { staticClass: "sidebar__divider" }),
      _c(
        "router-link",
        { staticClass: "sidebar__item", attrs: { to: { name: "Promotion" } } },
        [
          _c(
            "li",
            [
              _c("icon", {
                staticClass: "sidebar__icon",
                attrs: { name: "diamond" },
              }),
              _vm._v("Promotion"),
            ],
            1
          ),
        ]
      ),
      _c(
        "router-link",
        { staticClass: "sidebar__item", attrs: { to: { name: "Referral" } } },
        [
          _c(
            "li",
            [
              _c("icon", {
                staticClass: "sidebar__icon",
                attrs: { name: "refer" },
              }),
              _vm._v("Refer & Earn"),
            ],
            1
          ),
        ]
      ),
      _c(
        "router-link",
        {
          staticClass: "sidebar__item",
          attrs: { to: { name: "SellingPreferences" } },
        },
        [
          _c(
            "li",
            [
              _c("icon", {
                staticClass: "sidebar__icon",
                attrs: { name: "selling-preferences" },
              }),
              _vm._v("Selling Preferences"),
            ],
            1
          ),
        ]
      ),
      _c(
        "router-link",
        {
          staticClass: "sidebar__item",
          attrs: { to: { name: "EmailMarketing" } },
        },
        [
          _c(
            "li",
            [
              _c("icon", {
                staticClass: "sidebar__icon",
                attrs: { name: "newsletter" },
              }),
              _vm._v("Email Marketing"),
            ],
            1
          ),
        ]
      ),
      _c(
        "router-link",
        {
          staticClass: "sidebar__item",
          attrs: { to: { name: "Subscription" } },
        },
        [
          _c(
            "li",
            [
              _c("icon", {
                staticClass: "sidebar__icon",
                attrs: { name: "star" },
              }),
              _vm._v("Elite Account"),
            ],
            1
          ),
        ]
      ),
      _c(
        "router-link",
        { staticClass: "sidebar__item", attrs: { to: { name: "Settings" } } },
        [
          _c(
            "li",
            [
              _c("icon", {
                staticClass: "sidebar__icon",
                attrs: { name: "settings" },
              }),
              _vm._v("Settings"),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }