/* eslint-disable */
import './add'
import './addon'
import './arrow-down'
import './arrow-left'
import './arrow-right'
import './astronauts'
import './beats'
import './box'
import './camera'
import './cancel'
import './cart'
import './check'
import './close'
import './cloud-dl'
import './co-producer'
import './code'
import './contract'
import './coupon'
import './diamond'
import './download'
import './drums'
import './elitepage'
import './eye'
import './file'
import './fireworks'
import './folders'
import './followers'
import './hamburger'
import './home'
import './integration_youtube'
import './key'
import './license-options'
import './logo-icon'
import './logo'
import './money-bag'
import './network'
import './newsletter'
import './note'
import './pencil'
import './picture'
import './play'
import './plug'
import './product'
import './refer'
import './reorder'
import './search'
import './selling-preferences'
import './ServiceAlbum'
import './ServiceAlbums'
import './ServiceCamera'
import './ServiceCd'
import './ServiceGuitar1'
import './ServiceGuitar2'
import './ServiceHandMoney'
import './ServiceHandshake'
import './ServiceHeadphones'
import './ServiceMicrophone1'
import './ServiceMicrophone2'
import './ServiceMicrophone3'
import './ServiceMixing1'
import './ServiceMixing2'
import './ServiceMixing3'
import './ServiceMixing4'
import './ServiceMixing5'
import './ServiceMusicFile'
import './ServiceMusicFolder'
import './ServiceNote'
import './ServicePencil1'
import './ServicePencil2'
import './ServicePencil3'
import './ServicePicture'
import './ServiceRadio'
import './ServiceSpeakers'
import './settings'
import './share-filled'
import './star-o'
import './star'
import './store'
import './trash'
import './upload'
import './wallet'
import './warning'
import './zip'
