var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.toplineBannerShow,
            expression: "toplineBannerShow",
          },
        ],
        staticClass: "topLine",
      },
      [
        _c("router-link", { attrs: { to: { name: "Subscription" } } }, [
          _c("p", [
            _vm._v("Want more features and page customization? "),
            _c("span", { staticClass: "link" }, [_vm._v("Learn More")]),
          ]),
        ]),
        _c("icon", {
          attrs: { name: "cancel" },
          nativeOn: {
            click: function ($event) {
              return _vm.HIDE_TOPLINE_BANNER()
            },
          },
        }),
      ],
      1
    ),
    _c(
      "nav",
      {
        staticClass: "navbar",
        class: { "navbar--wTopline": _vm.toplineBannerShow },
      },
      [
        _vm.showMobileMenu
          ? _c(
              "a",
              {
                staticClass: "navbar__close",
                on: { click: _vm.toggleMobileMenu },
              },
              [_c("icon", { attrs: { name: "close" } })],
              1
            )
          : _vm._e(),
        _c("ul", { staticClass: "navbar--desktop navbar__items" }, [
          _c(
            "li",
            {
              staticClass:
                "navbar__item navbar__item--link navbar__item--right",
              class: { "navbar__item--hide": _vm.isSearch },
              on: {
                click: function ($event) {
                  return _vm.toggleMobileMenu(null)
                },
              },
            },
            [
              _c(
                "router-link",
                {
                  staticClass: "btn btn--darkGrey ",
                  attrs: {
                    to: {
                      name: "BeatsUpload",
                      query: { type: _vm.UploadType.NewBeat },
                    },
                  },
                },
                [_vm._v("Upload Beats")]
              ),
            ],
            1
          ),
          _vm.authenticatedUser
            ? _c("li", { staticClass: "navbar__item user-dropdown" }, [
                _c(
                  "a",
                  { staticClass: "flexbox" },
                  [
                    _c("img", {
                      staticClass: "user-dropdown__user-img",
                      attrs: {
                        src:
                          _vm.authenticatedUser.image_logo_url ||
                          "./img/profile-default.svg",
                      },
                      on: { click: _vm.goToProfile },
                    }),
                    _vm._m(0),
                    _c("icon", {
                      staticStyle: { "margin-left": "5px" },
                      attrs: {
                        name: "arrow-down",
                        width: "14px",
                        height: "14px",
                        color: "#DCDCDC",
                      },
                    }),
                  ],
                  1
                ),
                _c("ul", { staticClass: "user-dropdown__menu" }, [
                  _c(
                    "li",
                    { staticClass: "user-dropdown__item" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: { name: "Settings" } } },
                        [_vm._v("Settings")]
                      ),
                    ],
                    1
                  ),
                  _c("li", { staticClass: "user-dropdown__item" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://thecharts.com/producer/" +
                            _vm.authenticatedUser.slug,
                          target: "blank",
                        },
                      },
                      [_vm._v("View Profile")]
                    ),
                  ]),
                  _c("li", { staticClass: "user-dropdown__item" }, [
                    _c("a", { on: { click: _vm.finalLogout } }, [
                      _vm._v("Log Out"),
                    ]),
                  ]),
                ]),
              ])
            : _vm._e(),
        ]),
        _c("ul", { staticClass: "navbar--mobile navbar__items" }, [
          _c(
            "li",
            { staticClass: "navbar__item navbar__logo" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "Home" } } },
                [
                  _c("icon", {
                    staticClass: "navbar__logo-icon",
                    attrs: {
                      name: "logo",
                      width: "148",
                      height: "32",
                      original: "",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c("li", { staticClass: "navbar__item navbar__item--right" }, [
            _c(
              "a",
              { on: { click: _vm.toggleMobileMenu } },
              [_c("icon", { attrs: { name: "hamburger" } })],
              1
            ),
          ]),
        ]),
        _c(
          "ul",
          {
            staticClass: "navbar--mobile navbar__panel-mobile navbar__items",
            class: { "navbar__panel-mobile--open": _vm.showMobileMenu },
          },
          [
            _c(
              "li",
              {
                staticClass: "navbar__item",
                on: { click: _vm.toggleMobileMenu },
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "Dashboard" } } },
                  [
                    _c("icon", {
                      staticClass: "sidebar__icon",
                      attrs: { name: "home" },
                    }),
                    _vm._v("Dashboard "),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              {
                staticClass: "navbar__item",
                on: { click: _vm.toggleMobileMenu },
              },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: { name: "BeatsCatalog", params: { pageIndex: 1 } },
                    },
                  },
                  [
                    _c("icon", {
                      staticClass: "sidebar__icon",
                      attrs: { name: "beats" },
                    }),
                    _vm._v("Beats"),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              {
                staticClass: "navbar__item",
                on: { click: _vm.toggleMobileMenu },
              },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "BeatsUpload",
                        query: { type: _vm.UploadType.NewBeat },
                      },
                    },
                  },
                  [
                    _c("icon", {
                      staticClass: "sidebar__icon",
                      attrs: { name: "upload" },
                    }),
                    _vm._v("Upload Files"),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              {
                staticClass: "navbar__item",
                on: { click: _vm.toggleMobileMenu },
              },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "BeatsUpload",
                        query: { type: _vm.UploadType.TaggedBeat },
                      },
                    },
                  },
                  [
                    _c("icon", {
                      staticClass: "sidebar__icon",
                      attrs: { name: "upload" },
                    }),
                    _vm._v("Upload Tagged Beats"),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              {
                staticClass: "navbar__item",
                on: { click: _vm.toggleMobileMenu },
              },
              [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "BeatsUpload",
                        query: { type: _vm.UploadType.Public },
                      },
                    },
                  },
                  [
                    _c("icon", {
                      staticClass: "sidebar__icon",
                      attrs: { name: "picture" },
                    }),
                    _vm._v("Upload Artworks"),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              {
                staticClass: "navbar__item",
                on: { click: _vm.toggleMobileMenu },
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "BeatsReorder" } } },
                  [
                    _c("icon", {
                      staticClass: "sidebar__icon",
                      attrs: { name: "reorder" },
                    }),
                    _vm._v("Reorder Beats"),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              {
                staticClass: "navbar__item",
                on: { click: _vm.toggleMobileMenu },
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "BeatsPrivateVault" } } },
                  [
                    _c("icon", {
                      staticClass: "sidebar__icon",
                      attrs: { name: "beats" },
                    }),
                    _vm._v("Private Beats Vault"),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              {
                staticClass: "navbar__item",
                on: { click: _vm.toggleMobileMenu },
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "Licenses" } } },
                  [
                    _c("icon", {
                      staticClass: "sidebar__icon",
                      attrs: { name: "license-options" },
                    }),
                    _vm._v("License Options"),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              {
                staticClass: "navbar__item",
                on: { click: _vm.toggleMobileMenu },
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "Contracts" } } },
                  [
                    _c("icon", {
                      staticClass: "sidebar__icon",
                      attrs: { name: "contract" },
                    }),
                    _vm._v("Contracts"),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              {
                staticClass: "navbar__item",
                on: { click: _vm.toggleMobileMenu },
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "Services" } } },
                  [
                    _c("icon", {
                      staticClass: "sidebar__icon",
                      attrs: { name: "network" },
                    }),
                    _vm._v("Services"),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              {
                staticClass: "navbar__item",
                on: { click: _vm.toggleMobileMenu },
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "SoundKits" } } },
                  [
                    _c("icon", {
                      staticClass: "sidebar__icon",
                      attrs: { name: "drums" },
                    }),
                    _vm._v("Sound Kits"),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              {
                staticClass: "navbar__item",
                on: { click: _vm.toggleMobileMenu },
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "Coupons" } } },
                  [
                    _c("icon", {
                      staticClass: "sidebar__icon",
                      attrs: { name: "coupon" },
                    }),
                    _vm._v("Coupons "),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              {
                staticClass: "navbar__item",
                on: { click: _vm.toggleMobileMenu },
              },
              [
                _c(
                  "router-link",
                  {
                    attrs: { to: { name: "Orders", params: { pageIndex: 1 } } },
                  },
                  [
                    _c("icon", {
                      staticClass: "sidebar__icon",
                      attrs: { name: "wallet" },
                    }),
                    _vm._v("Sales "),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              {
                staticClass: "navbar__item",
                on: { click: _vm.toggleMobileMenu },
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "CoProducers" } } },
                  [
                    _c("icon", {
                      staticClass: "sidebar__icon",
                      attrs: { name: "co-producer" },
                    }),
                    _vm._v("Co-producers "),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              {
                staticClass: "navbar__item",
                on: { click: _vm.toggleMobileMenu },
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "AmplifyPlayer" } } },
                  [
                    _c("icon", {
                      staticClass: "sidebar__icon",
                      attrs: { name: "code" },
                    }),
                    _vm._v("Amplify Player"),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              {
                staticClass: "navbar__item",
                on: { click: _vm.toggleMobileMenu },
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "Product" } } },
                  [
                    _c("icon", {
                      staticClass: "sidebar__icon",
                      attrs: { name: "product" },
                    }),
                    _vm._v("Product Offer"),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              {
                staticClass: "navbar__item",
                on: { click: _vm.toggleMobileMenu },
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "Elitepage" } } },
                  [
                    _c("icon", {
                      staticClass: "sidebar__icon",
                      attrs: { name: "elitepage" },
                    }),
                    _vm._v("Elite Profile"),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              {
                staticClass: "navbar__item",
                on: { click: _vm.toggleMobileMenu },
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "Promotion" } } },
                  [
                    _c("icon", {
                      staticClass: "sidebar__icon",
                      attrs: { name: "diamond" },
                    }),
                    _vm._v("Promotion"),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              {
                staticClass: "navbar__item",
                on: { click: _vm.toggleMobileMenu },
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "Referral" } } },
                  [
                    _c("icon", {
                      staticClass: "sidebar__icon",
                      attrs: { name: "refer" },
                    }),
                    _vm._v("Refer & Earn"),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              {
                staticClass: "navbar__item",
                on: { click: _vm.toggleMobileMenu },
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "SellingPreferences" } } },
                  [
                    _c("icon", {
                      staticClass: "sidebar__icon",
                      attrs: { name: "selling-preferences" },
                    }),
                    _vm._v("Selling Preferences"),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              {
                staticClass: "navbar__item",
                on: { click: _vm.toggleMobileMenu },
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "EmailMarketing" } } },
                  [
                    _c("icon", {
                      staticClass: "sidebar__icon",
                      attrs: { name: "newsletter" },
                    }),
                    _vm._v("Email Marketing"),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              {
                staticClass: "navbar__item",
                on: { click: _vm.toggleMobileMenu },
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "Subscription" } } },
                  [
                    _c("icon", {
                      staticClass: "sidebar__icon",
                      attrs: { name: "star" },
                    }),
                    _vm._v("Elite Account"),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              {
                staticClass: "navbar__item",
                on: { click: _vm.toggleMobileMenu },
              },
              [
                _c(
                  "router-link",
                  { attrs: { to: { name: "Settings" } } },
                  [
                    _c("icon", {
                      staticClass: "sidebar__icon",
                      attrs: { name: "settings" },
                    }),
                    _vm._v("Settings"),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "li",
              {
                staticClass: "navbar__item",
                on: {
                  click: function ($event) {
                    return _vm.toggleMobileMenu(null)
                  },
                },
              },
              [
                _c(
                  "a",
                  {
                    attrs: {
                      href:
                        "https://thecharts.com/producer/" +
                        (_vm.authenticatedUser
                          ? _vm.authenticatedUser.slug
                          : ""),
                      target: "blank",
                    },
                  },
                  [_vm._v("View Profile")]
                ),
              ]
            ),
            _c(
              "li",
              {
                staticClass: "navbar__item",
                staticStyle: { "margin-bottom": "40px" },
                on: {
                  click: function ($event) {
                    return _vm.toggleMobileMenu(null)
                  },
                },
              },
              [_c("a", { on: { click: _vm.finalLogout } }, [_vm._v("Log Out")])]
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [_vm._v(_vm._s(_vm.authenticatedUser.display_name))])
  },
]
render._withStripped = true

export { render, staticRenderFns }