var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal__container" }, [
    _c(
      "div",
      { staticClass: "modalDialog" },
      [
        _c("icon", {
          staticClass: "modalcloseButton",
          attrs: { name: "cancel" },
          nativeOn: {
            click: function ($event) {
              return _vm.SET_ACTIVE_MODAL({ modal: null })
            },
          },
        }),
        _c("ValidationObserver", [
          _c("div", { staticClass: "modalContent" }, [
            _c(
              "div",
              { staticClass: "modalContent__body" },
              [
                _c("h2", { staticClass: "modal__title" }, [
                  _vm._v("Set Password for Vault"),
                ]),
                _c("ValidationProvider", {
                  attrs: { name: "Price", rules: { required: true } },
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function (ref) {
                        var errors = ref.errors
                        return [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.password,
                                expression: "password",
                              },
                            ],
                            staticClass: "formInput formInput--fullWidth",
                            class: { hasError: errors.lngth > 0 },
                            attrs: {
                              name: "name",
                              type: "text",
                              placeholder: "Enter password",
                            },
                            domProps: { value: _vm.password },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.password = $event.target.value
                              },
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
                _c("div", { staticClass: "h-mt-10" }),
                _c(
                  "a",
                  {
                    staticClass: "btn btn--fullWidth btn--success btn--border",
                    on: { click: _vm.updateVaultPassword },
                  },
                  [_vm._v("SAVE")]
                ),
              ],
              1
            ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }