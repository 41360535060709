var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal-manager" },
    [
      _vm.activeModal.modal === _vm.ModalType.Success
        ? _c("success")
        : _vm._e(),
      _vm.activeModal.modal === _vm.ModalType.Error ? _c("error") : _vm._e(),
      _vm.activeModal.modal === _vm.ModalType.ConfirmAction
        ? _c("confirm-action")
        : _vm._e(),
      _vm.activeModal.modal === _vm.ModalType.PayPalGuide
        ? _c("paypal-guide")
        : _vm._e(),
      _vm.activeModal.modal === _vm.ModalType.SetVaultPassword
        ? _c("set-vault-password")
        : _vm._e(),
      _vm.activeModal.modal === _vm.ModalType.Subscribe
        ? _c("subscribe")
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }