var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal__container" }, [
    _c(
      "div",
      { staticClass: "modalDialog" },
      [
        _c("icon", {
          staticClass: "modalcloseButton",
          attrs: { name: "cancel" },
          nativeOn: {
            click: function ($event) {
              return _vm.SET_ACTIVE_MODAL({ modal: null })
            },
          },
        }),
        _vm._m(0),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modalContent" }, [
      _c("div", { staticClass: "modalContent__body" }, [
        _c("div", { staticClass: "sa" }, [
          _c("div", { staticClass: "sa-error" }, [
            _c("div", { staticClass: "sa-error-x" }, [
              _c("div", { staticClass: "sa-error-left" }),
              _c("div", { staticClass: "sa-error-right" }),
            ]),
            _c("div", { staticClass: "sa-error-placeholder" }),
            _c("div", { staticClass: "sa-error-fix" }),
          ]),
        ]),
        _c("h2", { staticClass: "modal__title" }, [
          _vm._v("Unexpected error occured"),
        ]),
        _c("p", [
          _vm._v("Try again later and/or "),
          _c(
            "a",
            {
              staticClass: "link",
              attrs: {
                href: "https://clickandrap.com/support",
                target: "_blank",
              },
            },
            [_vm._v("contact support")]
          ),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }