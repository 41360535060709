var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal__container" }, [
    _c(
      "div",
      { staticClass: "modalDialog" },
      [
        _c("icon", {
          staticClass: "modalcloseButton",
          attrs: { name: "cancel" },
          nativeOn: {
            click: function ($event) {
              return _vm.SET_ACTIVE_MODAL({ modal: null })
            },
          },
        }),
        _c(
          "div",
          { staticClass: "modalContent modalContent--sideBySideButtons" },
          [
            _c("div", { staticClass: "modalContent__body" }, [
              _c("h2", { staticClass: "modal__title" }, [
                _vm._v("Are you sure?"),
              ]),
              _c(
                "a",
                {
                  staticClass: "btn btn--grey btn--border",
                  on: {
                    click: function ($event) {
                      return _vm.SET_ACTIVE_MODAL({ modal: null })
                    },
                  },
                },
                [_vm._v("CANCEL")]
              ),
              _c(
                "a",
                { staticClass: "btn btn--danger", on: { click: _vm.callback } },
                [_vm._v("YES")]
              ),
            ]),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }