var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal__container modal__container--overlay" },
    [
      _c(
        "div",
        {
          staticClass:
            "modalDialog modalDialog--noPadding modalDialog--withLongText",
        },
        [
          _c("div", { staticClass: "modalContent modalContent--column" }, [
            _c(
              "div",
              { staticClass: "modalContent__top" },
              [
                _c("icon", {
                  staticClass: "modalcloseButton",
                  attrs: { name: "cancel" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.SET_ACTIVE_MODAL({ modal: null })
                    },
                  },
                }),
                _c("h3", { staticClass: "modal__title" }, [
                  _vm._v("PayPal Rest API Guide"),
                ]),
              ],
              1
            ),
            _vm._m(0),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modalContent__body" }, [
      _c("div", { staticClass: "step" }, [
        _c("h3", { staticClass: "step__title" }, [_vm._v("1. Log In")]),
        _c("p", [
          _vm._v("Head over to "),
          _c(
            "a",
            {
              staticClass: "link link--underline",
              attrs: {
                href: "https://developer.paypal.com/developer/applications",
                target: "_blank",
              },
            },
            [_vm._v("PayPal Apps")]
          ),
          _vm._v(" and login."),
        ]),
      ]),
      _c("div", { staticClass: "step" }, [
        _c("h3", { staticClass: "step__title" }, [
          _vm._v("2. Create REST API apps"),
        ]),
        _c("p", [
          _vm._v(
            'Inside "My Apps & Credentials" scroll down to "REST API apps" and click the '
          ),
          _c("strong", [_vm._v("Create App")]),
          _vm._v(" button."),
        ]),
      ]),
      _c("div", { staticClass: "step" }, [
        _c("h3", { staticClass: "step__title" }, [_vm._v("3. App Settings")]),
        _c("p", [
          _vm._v('In the field called "App Name" write '),
          _c("strong", [_vm._v("TheCharts")]),
          _vm._v(" and click the button to create the app."),
        ]),
      ]),
      _c("div", { staticClass: "step" }, [
        _c("h3", { staticClass: "step__title" }, [
          _vm._v("4. API credentials"),
        ]),
        _c("p", [
          _vm._v("Once you've created the app, switch the "),
          _c("strong", [_vm._v("toggle button")]),
          _vm._v(
            ' to "Live", then copy "Client ID" and "Secret" in the corresponding fields in TheCharts.'
          ),
        ]),
        _c("p", { staticClass: "textIsWarning" }, [
          _vm._v("Important! Switch to "),
          _c("strong", [_vm._v("live mode")]),
          _vm._v(" or payments will not work"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }